/*
 * File: error_404.js
 * Project: our-wave-admin-app
 *
 * Created by Brendan Michaelsen on December 31, 2021 at 2:14 PM
 * Copyright © 2021 - 2024 Our Wave, Inc. All rights reserved.
 *
 * Last Modified: April 10, 2024 at 12:10 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Requires
 */

// Babel
require('core-js/stable');
require('regenerator-runtime/runtime');

// Modules
const { getComponentState } = require('../../modules/general');
const navigation = require('../../modules/navigation');
const userModule = require('../../modules/user');

// Themes
require('../../../css/themes/full.css');
require('../../../css/components/pages/auth.css');


/**
 * Init
 */

// Initialize navigation
navigation.initialize(getComponentState());

// Initialize user module
userModule.initialize(getComponentState());

// Perform user update
userModule.handleUpdateUser();
